export default {
    init() {
        // JavaScript to be fired on all pages

        $('.read__more__title').click(function() {
            $('.page__slider').resize();
        });

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        $('.goto--top').on('click', function () {
            $('html,body').animate({ scrollTop: 0 }, 'slow');
        });

        referentiesSliderArrowFix();
        function referentiesSliderArrowFix() {
            setTimeout(function(){
                var leftArrowPosition = ($(window).width() - $('.referenties__text').innerWidth()) / 2;
                var rightArrowPosition = ($(window).width() - $('.referenties__text').innerWidth()) / 2;
                $('.referenties__slider .slick--prev').attr('style', 'left:' + leftArrowPosition + 'px');
                $('.referenties__slider .slick--next').attr('style', 'right:' + rightArrowPosition + 'px');
             }, 1000);
        }
        $( window ).resize(function() {
            referentiesSliderArrowFix();
        });

        // Slick slider banner
        $('.referenties__slider').slick({
            autoplay: true,
            autoplaySpeed: 3000,
            draggable: true,
            arrows: true,
            dots: false,
            fade: true,
            speed: 900,
            infinite: true,
            prevArrow: '<span class="slick--prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"/></svg></span>',
            nextArrow: '<span class="slick--next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"/></svg></span>',
        });

        if ($(window).width() < 768) {
            $('.front__news__slider').slick({
                autoplay: true,
                autoplaySpeed: 3000,
                draggable: true,
                arrows: true,
                dots: false,
                fade: false,
                speed: 900,
                infinite: true,
                slidesToShow: 1,
                prevArrow: '<span class="front__news__prev slick--prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"/></svg></span>',
                nextArrow: '<span class="front__news__next slick--next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"/></svg></span>',
            });
        }

        if ($(window).width() < 1200) {
            $('.usp__inner').slick({
                dots: false,
                infinite: true,
                speed: 500,
                fade: false,
                cssEase: 'linear',
                arrows: false,
                autoplay: true,
                autoplaySpeed: 6000,
                slidesToShow: 4,
                variableWidth: true,
                draggable: true,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            variableWidth: false,
                        },
                    },
                ],
            });
        }



        $('.home .methods__inner').slick({
            dots: false,
            infinite: true,
            speed: 500,
            fade: false,
            cssEase: 'linear',
            autoplay: true,
            slidesToShow: 5,
            draggable: true,
            prevArrow: '<span class="slick--prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"/></svg></span>',
            nextArrow: '<span class="slick--next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"/></svg></span>',
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });

        $(window).on('load', function () {
            const hash = window.location.hash;
            const scrollToElement = $('' + hash + '');

            if (hash.length && scrollToElement) {
                const windowWidth = $(window).width();
                let topMargin = 100;

                if (windowWidth < 768) {
                    topMargin = 20;
                }

                $('html, body').animate({
                    scrollTop: scrollToElement.offset().top - topMargin,
                }, 250);
            }
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
